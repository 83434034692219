<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row v-show="display === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="pt-2 pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="2" class="pt-4">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    dense
                                    solo
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thick"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2" class="pt-4">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="6" md="3" >
                                    <v-radio-group
                                    v-model="shortage"
                                    row
                                    >
                                    <v-radio
                                        label="All"
                                        value="All"
                                    ></v-radio>
                                    <v-radio
                                        label="Available"
                                        value="N"
                                    ></v-radio>
                                    <v-radio
                                        label="Shortage"
                                        value="Y"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="5" class="pt-4">
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mr-3" style="padding: 19px;" small color="info" elevation="2" @click="search()"><v-icon class="mr-1">mdi-magnify</v-icon> Search</v-btn>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 mr-3 text-white"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcel"
                                            :fields="headersColumn"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </div>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-2" v-show="display === 'block' ? true : false">
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title>
                        <div style="width: 100%;">
                            <v-sheet
                                class="mx-auto"
                            >
                                <v-slide-group
                                multiple
                                show-arrows
                                >
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined>
                                            <v-card-text class="p-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        Total WGT OH CR:
                                                        <h6 style="text-align: right;font-weight: 600;" class="mt-0 pt-0">
                                                            {{$store.getters.convertToCurrencyUs(total_wgt_crc)}} Ton
                                                        </h6>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined>
                                            <v-card-text class="p-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        Total WGT FG:
                                                        <h6 style="text-align: right;font-weight: 600;" class="mt-0 pt-0">
                                                            {{$store.getters.convertToCurrencyUs(total_wgt_fg)}} Ton
                                                        </h6>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined>
                                            <v-card-text class="p-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        Total WGT PO:
                                                        <h6 style="text-align: right;font-weight: 600;" class="mt-0 pt-0">
                                                            {{$store.getters.convertToCurrencyUs(total_wgt_po)}} Ton
                                                        </h6>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined>
                                            <v-card-text class="p-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        Total WGT Order:
                                                        <h6 style="text-align: right;font-weight: 600;" class="mt-0 pt-0">
                                                            {{$store.getters.convertToCurrencyUs(total_wgt_order)}} Ton
                                                        </h6>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined>
                                            <v-card-text class="p-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        Total WGT Avail:
                                                        <h6 style="text-align: right;font-weight: 600;" class="mt-0 pt-0">
                                                            {{$store.getters.convertToCurrencyUs(total_wgt_avail)}} Ton
                                                        </h6>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                </v-slide-group>
                            </v-sheet>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="crc_avails"
                            class="elevation-1 mt-2"
                            :items-per-page="-1"
                            :loading="loading"
                            height="520"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :item-class="tr_datatable"
                        >
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.thick) }} mm
                            </template>
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.width) }} mm 
                            </template>
                            <template v-slot:[`item.wgt_oh_crc`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_oh_crc) }} Ton
                            </template>
                            <template v-slot:[`item.wgt_fg`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_fg) }} Ton
                            </template>
                            <template v-slot:[`item.wgt_po`]="{ item }" >
                                <span v-if="item.wgt_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po')">
                                    {{ $store.getters.convertToCurrencyUs(item.wgt_po) }} Ton
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.wgt_po) }} Ton
                                </span>
                            </template>
                            <template v-slot:[`item.wgt_order`]="{ item }">
                                <span v-if="item.wgt_order > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order')">
                                    {{ $store.getters.convertToCurrencyUs(item.wgt_order) }} Ton
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.wgt_order) }} Ton
                                </span> 
                            </template>
                            <template v-slot:[`item.wgt_Avail`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_Avail) }} Ton
                            </template>
                            <template slot="body.append">
                                <tr>
                                    <th colspan="2">Total</th>
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_wgt_crc)}} Ton</th> 
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_wgt_fg)}} Ton</th> 
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_wgt_po)}} Ton</th> 
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_wgt_order)}} Ton</th> 
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_wgt_avail)}} Ton</th> 
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-show="displayItem === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="elevation-5 rounded-l" outlined>
                    <v-card-title >
                        <h6 style="font-weight:600">{{jenis === 'po' ? 'PO Outstandings : ' : 'Order Outstandings : ' }} {{ jenis === 'po' ?  $store.getters.convertToCurrencyUs(wgt_po) + ' Kg' :  $store.getters.convertToCurrencyUs(wgt_order) + ' Kg' }}</h6>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="close()"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            :headers="header_details"
                            :items="details"
                            class="elevation-1 mt-2"
                            :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50]
                            }"
                            :items-per-page="30"
                            :loading="loading_detail"
                            height="578"
                            fixed-header
                            :divider="true"
                            :light="true"
                        >
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.thick) }} mm
                            </template>
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.width) }} mm 
                            </template>
                            <template v-slot:[`item.wgt_out`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_out) }} Kg
                            </template>
                            <template v-slot:[`item.period`]="{ item }">
                                {{ item.req_year }} - {{ item.req_month }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            display: 'block',
            displayItem: 'none',
            detailItem: '',
            detail: false,
            start_period: '',
            end_period: '',
            menu: false,
            modal: false,
            loading: false,
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'CRC Availability',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machines: [],
            machine: '',
            options: {},
            headers: [
                { text: 'Thick', value: 'thick', align:'right' },
                { text: 'Width', value: 'width', align:'right' },
                { text: 'Wgt OH CRC', value: 'wgt_oh_crc', align:'right' },
                { text: 'Wgt FG', value: 'wgt_fg', align:'right' },
                { text: 'Wgt PO', value: 'wgt_po', align:'right' },
                { text: 'Wgt Order', value: 'wgt_order', align:'right' },
                { text: 'Wgt Avail', value: 'wgt_Avail', align:'right' }
            ],
            crc_avails: [],
            searchItem: '',
            jenis: '',
            wgt_po: 0,
            wgt_order: 0,
            header_details: [],
            details: [],
            loading_detail: false,
            headersColumn: {
                'Thick': 'thick',
                'Width': 'width',
                'Wgt OH CRC' : 'wgt_oh_crc',
                'Wgt FG' : 'wgt_fg',
                'Wgt PO' : 'wgt_po',
                'Wgt Order' : 'wgt_order',
                'Wgt Avail' : 'wgt_Avail'
            },
            shortage:''
        }
    },
    async mounted(){
        this.shortage = 'All'
        this.$store.dispatch('setOverlay', true)
        await this.search()
        await this.getThick()
        await this.getWidth()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        close(){
            this.display = 'block'
            this.displayItem = 'none'
            this.header_details = []
            this.details = []
            this.search()
        },
        tr_datatable(item) {
            const rowClass = item.wgt_Avail > 0 ? 'tr_green' : 'tr_datatable'
            return rowClass;
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/crcThicks`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/crcWidth`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async search(){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/v2/sr/crc-avail-daily?thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&shortage=${this.shortage ? this.shortage : ''}&search=${this.searchItem ? this.searchItem : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.crc_avails = res.data.data
                this.loading = false
            })
        },
        async showDetail(item, jenis){
            this.header_details = []
            this.details = []
            this.loading_detail = true
            this.detailItem = item
            this.jenis = jenis
            this.display = 'none'
            this.displayItem = 'block'
            this.wgt_po = item.wgt_po
            this.wgt_order = item.wgt_order
            var reqBody = {
                'thick': item.thick,
                'width': item.width
            }
            if (jenis === 'po') {
                this.header_details  = [
                    { text: 'PO ID', value: 'po_id', align:'left' },
                    { text: 'Vendor', value: 'vendor_name', align:'left' },
                    { text: 'Period', value: 'period', align:'left' },                    
                    { text: 'Prodcode', value: 'prod_code', align:'left' },
                    { text: 'Product', value: 'article', align:'left' },
                    { text: 'Thick', value: 'thick', align:'right' },
                    { text: 'Width', value: 'width', align:'right' },
                    { text: 'Wgt Out', value: 'wgt_out', align:'right' }
                ]

                const respData = await backendApi.fetchCore('/v2/sr/crc-avail-daily/showPO', reqBody, false, false, false)
                
                if (respData.status === 200) {
                    this.loading_detail = false
                    this.details = respData.data.data
                }
            } else {
                this.header_details  = [
                    { text: 'Order ID', value: 'order_id', align:'left' },
                    { text: 'Region', value: 'region', align:'left' },
                    { text: 'Customer', value: 'cust_name', align:'left' },
                    { text: 'Period', value: 'period', align:'left' },                    
                    { text: 'Prodcode', value: 'prod_code', align:'left' },
                    { text: 'Product', value: 'article', align:'left' },
                    { text: 'Thick', value: 'thick', align:'right' },
                    { text: 'Width', value: 'width', align:'right' },
                    { text: 'Wgt Out', value: 'wgt_out', align:'right' }
                ]

                const respDataOrder = await backendApi.fetchCore('/v2/sr/crc-avail-daily/showOrder', reqBody, false, false, false)
                
                if (respDataOrder.status === 200) {
                    this.loading_detail = false
                    this.details = respDataOrder.data.data
                }
            }
        },
        async exportExcel(){
            const respData = await backendApi.fetchCore(`/v2/sr/crc-avail-daily?thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&shortage=${this.shortage ? this.shortage : ''}&search=${this.searchItem ? this.searchItem : ''}`, false, false, false)

            if (respData.status === 200) {
                return respData.data.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch: {
    },
    computed:{
        total_wgt_crc(){
            var total_wgt_crc = 0
            for (let index = 0; index < this.crc_avails.length; index++) {
                total_wgt_crc = total_wgt_crc + Number(this.crc_avails[index].wgt_oh_crc);
            }
            
            return (total_wgt_crc | 0)
        },
        total_wgt_fg(){
            var total_wgt_fg = 0
            for (let index = 0; index < this.crc_avails.length; index++) {
                total_wgt_fg = total_wgt_fg + Number(this.crc_avails[index].wgt_fg);
            }
            
            return (total_wgt_fg | 0)
        },
        total_wgt_po(){
            var total_wgt_po = 0
            for (let index = 0; index < this.crc_avails.length; index++) {
                total_wgt_po = total_wgt_po + Number(this.crc_avails[index].wgt_po);
            }
            
            return (total_wgt_po | 0)
        },
        total_wgt_order(){
            var total_wgt_order = 0
            for (let index = 0; index < this.crc_avails.length; index++) {
                total_wgt_order = total_wgt_order + Number(this.crc_avails[index].wgt_order);
            }
            
            return (total_wgt_order | 0)
        },
        total_wgt_avail(){
            var total_wgt_avail = 0
            for (let index = 0; index < this.crc_avails.length; index++) {
                total_wgt_avail = total_wgt_avail + Number(this.crc_avails[index].wgt_Avail);
            }
            
            return (total_wgt_avail | 0)
        },
    }
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #ffff !important;
}
.tr_green{
  background-color: #C8E6C9 !important;
}
</style>